<template>
    <div id="principal" class="container-fluid p-1">
        <div v-if="telaPronta" class="d-flex flex-column bg-white card-data">
            <!-- Slot com informações dos filtros aplicados -->
            <div id="idDetalhesFiltros">
                <div class="card-header card-header-filter size-font-label mb-2 py-0">
                    <span>{{ descricaoTipoSolicitacao() }}</span>
                </div>
                <div class="d-flex flex-column mx-2 fz-70 text-muted">
                    <p class="m-0 p-0">
                        <b>Período: </b><span>{{ filtro.dataInicial }}</span> <b> - </b><span>{{ filtro.dataFinal }}</span>
                    </p>
                    <p v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value" class="m-0 p-0">
                        <b>Tipo de Requisições: </b>
                        <span>{{
                            _descricaoItens(filtro.TiposRequisicao, {
                                textoTodos: 'Todas'
                            })
                        }}</span>
                    </p>
                    <p v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="m-0 p-0">
                        <b>Tipo de Operação: </b>
                        <span>{{
                            _descricaoItens(filtro.TiposOperacao, {
                                textoTodos: 'Todos'
                            })
                        }}</span>
                    </p>

                    <p class="m-0 p-0">
                        <b>Status: </b><span>{{ _descricaoItens(filtro.status) }}</span>
                    </p>
                    <p class="m-0 p-0">
                        <b>Etapa: </b
                        ><span>{{
                            _descricaoItens(filtro.etapasProcesso, {
                                textoTodos: 'Todas'
                            })
                        }}</span>
                    </p>
                    <p v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value" class="m-0 p-0">
                        <b>Responsável (vendedor): </b
                        ><span>{{
                            _descricaoItens(filtro.vendedores, {
                                funcaoDisplay: function (x) {
                                    return x.Nome;
                                }
                            })
                        }}</span>
                    </p>
                    <p v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="m-0 p-0">
                        <b>Cliente/Fornecedor: </b><span>{{ filtro.clienteFornecedor.Nome || 'Todos' }}</span>
                    </p>
                    <p v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="m-0 p-0">
                        <b>Natureza de Operação: </b><span>{{ _descricaoItens(filtro.naturezasOperacao, { textoTodos: 'Todas' }) }}</span>
                    </p>
                    <p class="m-0 p-0">
                        <b>Produto: </b><span>{{ filtro.produto || "Todos" }}</span>
                    </p>
                </div>
            </div>

            <!-- Div com as requisições VERSÃO DESK-->
            <div id="idGridRequisicoes" v-if="!isMobile && listaMovimentacoesRequisicao.length" class="mx-1">
                <div class="mb-1 scroller">
                    <table class="table table-sm mt-3 mb-0">
                        <thead>
                            <tr>
                                <th class="text-center cw-1">Data</th>
                                <th v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value" class="text-center cw-3">Tipo de Requisição</th>
                                <th v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value" class="text-center cw-1"># Requisição</th>
                                <th v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value" class="text-center cw-3">Responsável (vendedor)</th>
                                <th v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="text-center cw-1">Tipo Oper.</th>
                                <th v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="text-center cw-3">Cliente/Fornec</th>
                                <th v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="text-center cw-3">Nat. Operação</th>
                                <th class="text-center cw-2">Etapa</th>
                                <th class="text-center text-nowrap cw-1">Ocorrência?</th>
                                <th class="text-center text-nowrap cw-1">Status</th>
                                <!-- <th class="text-center cw-1"><i class="icon icon-lx-bars icon-lx-sm"></i></th> -->
                            </tr>
                        </thead>
                    </table>
                    <div class="table-responsive tabela-itens">
                        <table class="table table-sm table-striped table-hover shadow-sm">
                            <tbody>
                                <tr
                                    v-for="item in listaMovimentacoesRequisicao"
                                    class="cursor-hand"
                                    :key="item.IdWMSRequisicao"
                                    :class="{ 'bg-color-secondary': item.Selecionado }"
                                    @click="toggleSelecaoRequisicao(item)"
                                >
                                    <td class="text-center cw-1">
                                        {{ $moment(item.DataRequisicao).format('DD/MM/YYYY') }}
                                    </td>
                                    <td v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value" class="text-center cw-3">
                                        {{ item.TipoRequisicaoDescricao }}
                                    </td>
                                    <td class="text-center cw-1">
                                        <a href="#"
                                            @click.stop="abrirModalConsultaRequisicao(item.IdWMSRequisicao)">
                                            {{ item.IdWMSRequisicao }}
                                        </a>
                                    </td>
                                    <td v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value" class="text-center cw-3">
                                        {{ item.VendedorNome }}
                                    </td>

                                    <td v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="text-center cw-1">
                                        {{ item.TipoOperacaoDescricao }}
                                    </td>
                                    <td v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="text-center cw-3">
                                        {{ item.NomeClienteFornecedor }}
                                    </td>
                                    <td v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value" class="text-center cw-3">
                                        {{ item.DescricaoNaturezaOperacao }}
                                    </td>
                                    <td class="text-center cw-2">
                                        {{ descricaoEtapa(item) }}
                                    </td>
                                    <td class="text-center text-nowrap cw-1">
                                        {{ contemDivergencia(item) | boolSimTraco }}
                                    </td>
                                    <td class="text-center text-nowrap cw-1">
                                        <span class="badge badge-pill" :class="{ 'badge-default': item.StatusId == 1, 'badge-info': item.StatusId == 2, 'badge-success': item.StatusId == 3 }">
                                            {{ item.StatusDescricao }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- Div com as requisições VERSÃO MOBILE-->
            <div id="idGridRequisicoesMobile" class="d-flex flex-column mx-1 px-1 p-0" v-else-if="isMobile && listaMovimentacoesRequisicao.length">
                <div
                    class="shadow-sm shadow-hover my-1"
                    v-for="(item, index) in listaMovimentacoesRequisicao"
                    v-bind:key="index"
                    :disable="item.BloqueiaAcaoPorUsuario"
                    :class="{ 'border border-primary bg-light': item.Selecionado }"
                    @click="toggleSelecaoRequisicao(item)"
                >
                    <div class="d-flex justify-content-between align-items-center bg-default m-0 p-1">
                        <!-- Id e Data da Requisição  -->
                        <div class="d-flex text-muted fz-80 label-orange">
                            <b class="px-1">                                 
                                <a  href="#" @click.stop="abrirModalConsultaRequisicao(item.IdWMSRequisicao)">
                                    # {{ item.IdWMSRequisicao }}
                                </a>
                            </b> - {{ $moment(item.DataRequisicao).format('DD/MM/YYYY') }}
                        </div>
                        <div :class="['d-flex', usuarioLogado.Id == item.IdUsuarioBloqueio ? 'text-success' : 'text-danger']" v-if="item.BloqueiaAcaoPorUsuario">
                            <i :class="['fas', usuarioLogado.Id == item.IdUsuarioBloqueio ? 'fa-lock-open' : 'fa-user-lock']"></i>
                            <small class="pl-1">
                                <b>{{ item.NomeUsuarioBloqueio }} </b>
                            </small>
                        </div>
                    </div>
                    <div class="d-flex border">
                        <div class="mr-auto p-2 fz-70">
                            <div class="d-flex">
                                <div class="text-right d-flex flex-column mr-1">
                                    <p class="d-flex flex-column align-items-start text-left m-0" v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value">
                                        <small class="label-orange">Tipo Requisição: </small>
                                        <span class="text-muted size-font-label">
                                            {{ item.TipoRequisicaoDescricao }}
                                        </span>
                                    </p>
                                    <p class="d-flex flex-column align-items-start text-left m-0" v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value">
                                        <small class="label-orange">Responsável: </small>
                                        <span class="text-muted size-font-label">
                                            {{ item.VendedorNome }}
                                        </span>
                                    </p>
                                    <p class="d-flex flex-column align-items-start text-left m-0" v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value">
                                        <small class="label-orange">Tipo Operação: </small>
                                        <span class="text-muted size-font-label">
                                            {{ item.TipoOperacaoDescricao }}
                                        </span>
                                    </p>
                                    <p class="d-flex flex-column align-items-start text-left m-0" v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value">
                                        <small class="label-orange">Cliente/Fornec: </small>
                                        <span class="text-muted size-font-label">
                                            {{ item.NomeClienteFornecedor }}
                                        </span>
                                    </p>
                                    <p class="d-flex flex-column align-items-start text-left m-0" v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value">
                                        <small class="label-orange">Nat. Operação: </small>
                                        <span class="text-muted size-font-label">
                                            {{ item.DescricaoNaturezaOperacao }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="p-2">
                            <div class="d-flex flex-column align-items-end justify-content-end text-right">
                                <div class="fz-70" v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value">
                                    <small class="label-orange">NF / Série: </small>
                                    <span class="text-muted size-font-label font-size-bolder">
                                        <b>{{ item.Documento }} / {{ item.Serie }}</b>
                                    </span>
                                </div>
                                <div>
                                    <span class="badge badge-pill" :class="{ 'badge-default': item.StatusId == 1, 'badge-info': item.StatusId == 2, 'badge-success': item.StatusId == 3 }">
                                        {{ item.StatusDescricao }}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {{ descricaoEtapa(item) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <progress-bar size="small" text-fg-color="gray" text-align="left" :val="20" text="20%" text-position="top" :bar-color="item.StatusId === 2 ? 'blue' : 'green'"></progress-bar> -->
                </div>
                <!-- Botoes de ação da grid -->
                <!-- End Botoes de ação da grid -->
            </div>
            <!-- End div requisições -->

            <!-- Grid para tratar registros não encontrados ou filtro não aplicado -->
            <div :class="['text-center card-info-search', isMobile ? 'm-2' : 'm-5']">
                <div v-if="!this.listaMovimentacoesRequisicao.length" :class="{ 'd-flex align-items-center justify-content-center mt-2': isMobile }">
                    <div>
                        <span class="text-center">
                            <img :src="iconeInf" />
                        </span>
                    </div>
                    <div>
                        <span :class="['text-uppercase text-muted', isMobile ? 'fz-70' : 'fz-95']"> Nenhum registro encontrado com os filtros informados </span>
                    </div>
                </div>
            </div>
        </div>

        <base-modal
            v-if="isMobile && consultaRequisicao"
            v-show="modais.consulta.isModalVisible"
            @close="fecharModalConsultaRequisicao()"
            tituloModal="CONSULTA REQUISIÇÃO"
            :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
            :classesAdicionaisBackDrop="'align-items-start'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2 overflow-x-hidden'"            
        >
            <div slot="body">
                <div class="d-flex mx-0 fz-70 text-muted flex-column mb-2">
                    <p class="m-0 p-0">
                        <b>Data: </b>
                        <span>{{ consultaRequisicao.DataRequisicao | datePtBr }}</span>
                    </p>
                    <p  class="m-0 p-0" v-if="consultaRequisicao.TipoRequisicaoDescricao">
                        <b>Requisição: </b>
                        <span>{{ consultaRequisicao.TipoRequisicaoDescricao }}</span>
                    </p>                        
                    <p class="m-0 p-0" v-if="consultaRequisicao.TipoRequisicaoDescricao">
                        <b>Tipo de Requisição: </b>
                        <span>{{ consultaRequisicao.TipoRequisicaoDescricao }}</span>
                    </p>
                    <p class="m-0 p-0" v-if="consultaRequisicao.VendedorNome">
                        <b>Responsável (vendedor):  </b>
                        <span>{{ consultaRequisicao.VendedorNome }}</span>
                    </p>                        
                </div>  
                <div class="border p-0 mb-2 shadow-sm shadow-hover my-1" v-for="(item, indexItens) in consultaRequisicao.Itens" :key="indexItens">                           
                    <div class="d-flex flex-column fz-80">
                        <div class="p-2 pr-2 bg-default m-0 p-0">
                            <p class="fz-70 flex-grow-1 m-0 p-0 text-muted">
                                <strong>{{item.DescricaoProduto}}</strong>
                            </p>    
                        </div>
                        <div class="pl-2 pr-2">
                            <div class="d-flex align-items-center">
                                <p class="label-orange m-0 p-0"><small>Cód. Produto:</small></p>
                                <p class="fz-80 text-right flex-grow-1 m-0 p-0 text-muted">
                                    <strong>{{ item.CodigoProduto }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="pl-2 pr-2">
                            <div class="d-flex align-items-center">
                                <p class="label-orange m-0 p-0"><small>Referência:</small></p>
                                <p class="fz-80 text-right flex-grow-1 m-0 p-0 text-muted">
                                    <strong>{{item.Referencia}}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="pl-2 pr-2">
                            <div class="d-flex align-items-center">
                                <p class="label-orange m-0 p-0"><small>Depósito Origem:</small></p>
                                <p class="fz-80 text-right flex-grow-1 m-0 p-0 text-muted">
                                    <strong>{{ item.DepositoOrigem }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="pl-2 pr-2">
                            <div class="d-flex align-items-center">
                                <p class="label-orange m-0 p-0"><small>Depósito Destino:</small></p>
                                <p class="fz-80 text-right flex-grow-1 m-0 p-0 text-muted">
                                    <strong>{{ item.DepositoDestino }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="pl-2 pr-2">
                            <div class="d-flex align-items-center">
                                <p class="label-orange m-0 p-0"><small>Qtde Solicitada:</small></p>
                                <p class="fz-80 text-right flex-grow-1 m-0 p-0 text-muted">
                                    <strong>{{ item.QuantidadeSolicitada }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="pl-2 pr-2">
                            <div class="d-flex align-items-center">
                                <p class="label-orange m-0 p-0"><small>Qtde Separada Picking:</small></p>
                                <p class="fz-80 text-right flex-grow-1 m-0 p-0 text-muted">
                                    <strong>{{ item.QuantidadeSeparada }}</strong>
                                </p>
                            </div>
                        </div>
                        
                        <div class="pl-2 pr-2">
                            <div class="d-flex align-items-center">
                                <p class="label-orange m-0 p-0"><small>Qtde Armazenada:</small></p>
                                <p class="fz-80 text-right flex-grow-1 m-0 p-0 text-muted">
                                    <strong>{{ item.QuantidadeConferida }}</strong>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>                
        </base-modal>

        <base-modal
            v-if="!isMobile && consultaRequisicao"
            v-show="modais.consulta.isModalVisible"
            @close="fecharModalConsultaRequisicao()"
            tituloModal="CONSULTA REQUISIÇÃO"
            :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
            :classesAdicionaisBackDrop="'align-items-start'"            
            :classesAdicionaisBody="'py-2 overflow-x-hidden'"            
        >
            <div slot="body">
                <div class="container-fluid" style="max-height:80%"> 
                    <div class="row fz-90 text-muted">
                        <label class="col-sm-12"> 
                            <b>Data: </b><span>{{ consultaRequisicao.DataRequisicao | datePtBr }}</span> 
                        </label> 
                    </div>
                    <div class="row fz-90 text-muted"> 
                        <label class="col-sm-12"> 
                            <b>Requisição: </b><span>{{ consultaRequisicao.TipoRequisicaoDescricao }}</span> 
                        </label> 
                    </div>
                    <div class="row fz-90 text-muted">
                        <label class="col-sm-12">
                            <b>Tipo de Requisição: </b><span>{{ consultaRequisicao.TipoRequisicaoDescricao }}</span>
                        </label>
                    </div>
                    <div class="row fz-90 text-muted">
                        <label class="col-sm-12"> 
                            <b>Responsável (vendedor): </b><span>{{ consultaRequisicao.VendedorNome }}</span> 
                        </label> 
                    </div>
                </div>
                <div class="mb-1 scroller">
                    <table class="table table-sm mt-3 mb-0 table-striped table-hover shadow-sm border-bottom">
                        <thead>
                            <tr>
                            <th class="text-left cw-1">Cód. Produto</th>
                            <th class="text-left cw-1">Referência</th>
                            <th class="text-left cw-3">Descrição</th>                  
                            <th class="text-center cw-1">Qtde. Solicitada</th>
                            <th class="text-center cw-1">Qtde. Separada Picking</th>
                            <th class="text-left cw-2">Depósito Origem</th>
                            <th class="text-center cw-1">Qtde. Armazenada</th>
                            <th class="text-left cw-2">Depóstio Destino</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in consultaRequisicao.Itens" :key="index">
                            <td class="text-left cw-1">
                                {{item.CodigoProduto}}
                            </td>
                            <td class="text-left cw-1">
                                {{item.Referencia}}
                            </td>
                            <td class="text-left cw-3">
                                {{item.DescricaoProduto}}
                            </td>
                            <td class="text-center cw-1">
                                <strong>{{ item.QuantidadeSolicitada }}</strong>
                            </td>
                            <td class="text-center cw-1">
                                <strong>{{ item.QuantidadeSeparada }}</strong>
                            </td>
                            <td class="text-left cw-2">
                                <strong>{{ item.DepositoOrigem }}</strong>
                            </td>
                            <td class="text-center cw-1">
                                <strong>{{ item.QuantidadeConferida }}</strong>
                            </td> 
                            <td class="text-left cw-2">
                                <strong>{{ item.DepositoDestino }}</strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div slot="footer">
                <div class="text-right">
                    <button type="button" class="btn btn-secondary rounded" @click="fecharModalConsultaRequisicao()">Fechar</button> 
                </div>
            </div>
        </base-modal>

        <!-- Botão Voltar -->

        <div class="fixed-bottom bg-white mb-0 action-buttons">
            <div class="btn-group px-2 mt-1 w-100 flex justify-content-end">
                <div class="col-6">
                    <button
                        @click="onExecutarEtapaRequisicao(EtapaProcesso.PICKING.value)"
                        class="btn"
                        :disabled="itensEtapa[EtapaProcesso.PICKING.value].length == 0"
                        :class="[{ 'btn-block': isMobile }, !itensEtapa[EtapaProcesso.PICKING.value].length ? 'btn-light' : 'btn-warning']"
                    >
                        <div class="d-flex flex-column">
                            <span class="">
                                <i class="icon icon-lx-barcode"></i>
                                Picking
                            </span>
                            <span class="fz-70" v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value">{{ itensEtapa[EtapaProcesso.PICKING.value] | descRequisicao }}</span>
                            <span class="fz-70" v-else> {{ itensEtapa[EtapaProcesso.PICKING.value] | descOperacao }}</span>
                        </div>
                    </button>
                </div>
                <div class="col-6">
                    <button
                        @click="onExecutarEtapaRequisicao(EtapaProcesso.ARMAZENAGEM.value)"
                        class="btn"
                        :disabled="itensEtapa[EtapaProcesso.ARMAZENAGEM.value].length == 0"
                        :class="[{ 'btn-block': isMobile }, !itensEtapa[EtapaProcesso.ARMAZENAGEM.value].length ? 'btn-light' : 'btn-warning']"
                    >
                        <div class="d-flex flex-column">
                            <span>
                                <i class="icon icon-lx-barcode"></i>
                                Armazenagem
                            </span>
                            <span class="fz-70" v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value">{{ itensEtapa[EtapaProcesso.ARMAZENAGEM.value] | descRequisicao }}</span>
                            <span class="fz-70" v-else> {{ itensEtapa[EtapaProcesso.ARMAZENAGEM.value] | descOperacao }}</span>
                        </div>
                    </button>
                </div>
            </div>

            <div class="btn-group mb-0 px-2 w-100 flex justify-content-end">
                <button :class="['btn btn-primary m-1', { 'btn-block': isMobile }]" @click="marcarRequisicoes">{{ desmarcarSelecao ? 'Desmarcar' : 'Marcar' }} Todas</button>
                <button :class="['btn m-1', { 'btn-block': isMobile, 'btn-light': !controleMarcacao.agrupavel, 'btn-primary': controleMarcacao.agrupavel }]" @click="marcarGrupoRequisicoes">
                    Marcar Grupo
                </button>
                <button :class="['btn btn-default m-1', { 'btn-block': isMobile }]" @click="$router.push({ name: 'filtros-requisicoes' })">Voltar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from '@/common/alerts';
import loading from '@/common/loading';
import baseModal from '@/components/base-modal.vue';
import moment from 'moment-timezone';
//import ProgressBar from 'vue-simple-progress';

//Enumns
import TipoSolicitacao from '@/domain/tipo-solicitacao';
import EtapaProcesso from '@/domain/etapa-processo';
import AcaoRequisicaoWMS from '@/domain/acao-requisicao-wms';

//URLs Icones/Imagens
const imgIconInfo = require('@/assets/Images/linx-icon-ilustrativo/info.png');

export default {
    name: 'lista-movimentacoes',
    components: {
        baseModal
    },
    data() {
        return {
            TipoSolicitacao,
            EtapaProcesso,
            AcaoRequisicaoWMS,
            tipoMensagem: {
                Sucesso: 1,
                Erro: 2,
                Aviso: 3
            },
            telaPronta: false,
            iconeInf: imgIconInfo,
            idsRequisicoes: null,
            modais: {                
                consulta: {
                    isModalVisible: false
                }
            },
            controleMarcacao: {
                agrupavel: false,
                selecionadas: 0,
                selecionaveis: 1
            },
            permiteDesmarcarTodas: false,
            // o array não usa o index 0 para sincronizar com Ids das Etapas
            // eslint-disable-next-line no-sparse-arrays
            itensEtapa: [, [], [], [], []]

        };
    },
    computed: {
        ...mapGetters({
            isMobile: 'appData/ehWMSMobile',
            filtro: 'requisicoesWMS/filtrosRequisicoes',
            listaVendedores: 'requisicoesWMS/vendedores',
            listaTipoPesquisa: 'requisicoesWMS/tipoSolicitacao',
            listaStatus: 'requisicoesWMS/statusRequisicao',
            listaTiposRequisicao: 'requisicoesWMS/tiposRequisicao',
            listaNaturezaOperacoes: 'requisicoesWMS/naturezasOperacao',
            listaTiposOperacao: 'requisicoesWMS/tiposOperacao',
            listaMovimentacoesRequisicao: 'requisicoesWMS/requisicoes',
            consultaRequisicao: 'requisicoesWMS/consultaRequisicao',
            permissoes: 'requisicoesWMS/permissoesWMS',
            usuarioLogado: 'auth/usuarioLogado'
        }),
        desmarcarSelecao: function () {
            return this.controleMarcacao.selecionaveis == this.controleMarcacao.selecionadas;
        }
    },
    mounted() {
        let titlePage = this.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value ? 'Listagem das Requisições' : 'Listagem das Operações';
        this.$store.dispatch('uiState/updateNavbar', titlePage);
        this.carregarDadosIniciais();
    },
    filters: {
        datePtBr: function (value) {
            return moment(value, moment.ISO_8601).format("DD/MM/YYYY");
        },
        boolSimTraco: function (value) {
            return value === true ? 'Sim' : '-';
        },
        descRequisicao: function (value) {
            var total = value.reduce(function (total, idx) {
                return total + idx;
            }, 0);

            return total ? total + ' Requisiç' + (total <= 1 ? 'ão' : 'ões') : '';
        },
        descOperacao: function (value) {
            var total = value.reduce(function (total, idx) {
                return total + idx;
            }, 0);
            return total ? total + ' Operaç' + (total <= 1 ? 'ão' : 'ões') : '';
        }
    },
    methods: {
        async carregarDadosIniciais() {
            var self = this;
            loading.exibir();
            var parametrosFiltro = {
                ListarSomenteDivergencias: false,
                TipoSolicitacaoId: self.filtro.opcaoFiltro,
                DataInicial: this.$moment(self.filtro.dataInicial, 'DD/MM/YYYY').toISOString(),
                DataFinal: this.$moment(self.filtro.dataFinal, 'DD/MM/YYYY').toISOString(),
                StatusId: self._mapOrEmpty(self.filtro.status, 'Id'),
                EtapaProcessoRequisicaoId: self._mapOrEmpty(self.filtro.etapasProcesso, 'Id'),
                ProdutoId: self.filtro.produto
            };

            if(self.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value) {

                parametrosFiltro.TipoRequisicaoId = self._mapOrEmpty(self.filtro.tiposRequisicao, 'IdWMSTipoRequisicao'),
                parametrosFiltro.VendedorId = self._mapOrEmpty(self.filtro.vendedores, 'Id')
            }
            else if(self.filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value){

                parametrosFiltro.ClienteFornecedorId = self.filtro.clienteFornecedor?.Codigo
                parametrosFiltro.NaturezaOperacaoId= self._mapOrEmpty(self.filtro.naturezasOperacao, 'Id')
                parametrosFiltro.TipoOperacaoId= self.filtro.tipoOperacao ? self.filtro.tipoOperacao.Id : null
            }

            await this.$store
                .dispatch('requisicoesWMS/obterListaRequisicoesWMS', parametrosFiltro)
                .then(() => (this.telaPronta = true))
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários, tente novamente');
                    this.$router.push({ name: 'filtros-requisicoes' });
                })
                .finally(() => loading.ocultar());
        },

        _descricaoItens(lista, opcoes) {
            opcoes = opcoes || {};
            if (!opcoes.funcaoDisplay)
                opcoes.funcaoDisplay = function (x) {
                    return x.Descricao;
                };
            if (!opcoes.textoTodos) opcoes.textoTodos = 'Todos';
            if (!lista || lista.length == 0) return opcoes.textoTodos;
            return lista.map(opcoes.funcaoDisplay).join(', ');
        },

        //Métodos auxiliares
        _mapOrEmpty(array, field) {
            var self = this;
            if (array) return self.$_.map(array, field);
            return [];
        },
        descricaoTipoSolicitacao() {
            return this.listaTipoPesquisa.length && this.listaTipoPesquisa.filter((filtro) => filtro.Id == this.filtro.opcaoFiltro)[0].Descricao;
        },
        descricaoEtapa(item) {
            return item.EtapaProcessoId <= 3 && item.StatusId < 3 ? item.EtapaProcessoDescricao : '-';
        },
        contemDivergencia(item) {
            return item.PossuiDivergenciaPicking || item.PossuiDivergenciaArmazenagem;
        },
        habilitaOcorrencias(item) {
            return item.StatusId == 3 && this.contemDivergencia(item);
        },
        permiteCancelarRequisicao(item) {
            return item.StatusId == 1;
        },

        //Modal consulta requisição
        abrirModalConsultaRequisicao: async function (requisicao) {
            loading.exibir();            
            await this.$store
                .dispatch('requisicoesWMS/consultaRequisicaoWMS', requisicao)
                .then(() => {
                    this.modais.consulta.isModalVisible = true;
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários, tente novamente');
                })
                .finally(() => loading.ocultar());            
        },
        fecharModalConsultaRequisicao() {
            this.modais.consulta.isModalVisible = false;
        },

        //Métodos referente a seleção das requisições/operações para habilitar botões de ação
        _parametrosSeparacaoRequisicao(etapa) {
            return {
                Etapa: etapa,
                OpcaoFiltro: this.filtro.opcaoFiltro,
                Itens: this._retornaRequisicoesSelecionadas(etapa)
            };
        },
        _retornaRequisicoesSelecionadas(etapa) {
            return this.listaMovimentacoesRequisicao
                .filter(function (x) {
                    return x.StatusId < 3 && x.EtapaProcessoId == etapa && x.Selecionado;
                })
                .map(function (x) {
                    return {
                        IdWMSRequisicao: x.IdWMSRequisicao,
                        DataRequisicao: x.DataRequisicao,
                        TipoRequisicaoDescricao: x.TipoRequisicaoDescricao,
                        VendedorNome: x.VendedorNome,
                        Documento: x.Documento,
                        Serie: x.Serie,
                        NomeClienteFornecedor: x.NomeClienteFornecedor
                    };
                });
        },
        toggleSelecaoRequisicao(item) {
            if (item.BloqueiaAcaoPorUsuario && item.IdUsuarioBloqueio != this.usuarioLogado.Id && !this.permissoes.LiberaRequisicaoEmExecucao) {
                swal.exibirToastAlerta(`A requisicao: ${item.IdWMSRequisicao} está bloqueada para o usuário: ${item.NomeUsuarioBloqueio}, você não possui permissão para desbloqueá-la.`);
                return;
            }
            if (!item.Selecionado && (item.StatusId == 3 || item.EtapaProcessoId > 3)) return;
            item.Selecionado = !item.Selecionado;
            this._atualizaAcoesRequisicao();
        },
        _atualizaAcoesRequisicao() {
            // atualiza o array que contem as quantidades selecionadas
            // para cada etapa do processo para que possam ser colocadas
            // no titulo dos botões
            var self = this;
            var selecao = self.listaMovimentacoesRequisicao.filter(function (x) {
                return x.Selecionado;
            });
            var grupos =
                self.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value ? self._grupoSelecao(selecao, self._chaveGrupoRequisicoes) : self._grupoSelecao(selecao, self._chaveGrupoOperacoes);

            self.controleMarcacao.agrupavel = Object.keys(grupos).length == 1;
            self.controleMarcacao.selecionadas = selecao.length;
            self.controleMarcacao.selecionaveis = self.listaMovimentacoesRequisicao.filter(function (x) {
                return x.StatusId < 3 && x.EtapaProcessoId < 4;
            }).length;
            // eslint-disable-next-line no-sparse-arrays
            var _itensEtapa = [, [], [], [], []];
            self.$_.forIn(grupos, function (v, k) {
                var etapa = parseInt(k.split('|')[0]);
                _itensEtapa[etapa].push(v);
            });
            self.$_.forEach(_itensEtapa, function (item, i) {
                if (item)
                    self.itensEtapa[i] = item.map(function (x) {
                        return x.length;
                    });
            });
        },
        _grupoSelecao(selecao, chaveGrupo) {
            if (selecao.length) return this.$_.groupBy(selecao, chaveGrupo);
            return {};
        },
        _chaveGrupoRequisicoes(item) {
            if (item?.TipoRequisicaoVendaAmbulante) return item.EtapaProcessoId + '|' + item.TipoRequisicaoId + '|' + item.VendedorId;
            else return item?.EtapaProcessoId + '|' + item?.TipoRequisicaoId;
        },
        _chaveGrupoOperacoes(item) {
            return item.EtapaProcessoId + '|' + item.CodigoClienteFornecedor + '|' + item.TipoOperacaoId + '|' + item.CodigoNaturezaOperacao;
        },
        onExecutarEtapaRequisicao(etapa) {
            var self = this;
            //Verifica se a requisicao está bloqueada para o usuário logado

            if (!this._usuarioComPemissao()) return;

            if (self.itensEtapa[etapa].length > 1) {
                self.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value ? self._exibirMensagemValidacaoSelecaoRequisicao() : self._exibirMensagemValidacaoSelecaoOperacao();
                return;
            }

            //Incluir novo "Log" de controle de requisição para gerenciar os bloqueios por usuário
            self._alterarAcaoRequisicao(etapa);
        },
        async _alterarAcaoRequisicao(etapa) {
            var self = this;
            let acao = '';
            if (etapa == EtapaProcesso.PICKING.value) acao = AcaoRequisicaoWMS.PICKING_EM_ANDAMENTO.value;
            else if (etapa == EtapaProcesso.ARMAZENAGEM.value) acao = AcaoRequisicaoWMS.ARMAZENAGEM_EM_ANDAMENTO.value;

            self.idsRequisicoes = this.$_.map(self._retornaRequisicoesSelecionadas(etapa), 'IdWMSRequisicao');
            var params = {
                IdRequisicoesWMS: self.idsRequisicoes,
                AcaoRequisicao: acao
            };
            loading.exibir();
            await this.$store
                .dispatch('requisicoesWMS/alterarAcoesRequisicoesWMS', params)
                .then(() => {
                    this.$router.push({
                        name: 'itens-requisicoes',
                        params: { requisicoes: self._parametrosSeparacaoRequisicao(etapa), opcaoFiltro: self.filtro.opcaoFiltro }
                    });
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível concluir essa ação, tente novamente');
                })
                .finally(() => loading.ocultar());
        },
        _usuarioComPemissao(etapa) {
            var self = this;
            if (etapa == EtapaProcesso.PICKING.value && !self.permissoes.PickingColeta) {
                swal.exibirMensagemAlerta('O usuário não tem permissão para executar a etapa de Picking!');
                return false;
            }
            if (etapa == EtapaProcesso.ARMAZENAGEM.value && !self.permissoes.Armazenagem) {
                swal.exibirMensagemAlerta('O usuário não tem permissão para executar a etapa de Armazenagem!');
                return false;
            }
            return true;
        },

        marcarRequisicoes() {
            var self = this;
            if (self.desmarcarSelecao) {
                self.listaMovimentacoesRequisicao.forEach(function (x) {
                    x.Selecionado = false;
                });
            } else {
                self.listaMovimentacoesRequisicao
                    .filter(function (x) {
                        return !x.Selecionado && x.StatusId < 3 && x.EtapaProcessoId < 4;
                    })
                    .forEach(function (x) {
                        x.Selecionado = true;
                    });
            }
            self._atualizaAcoesRequisicao();
        },

        marcarGrupoRequisicoes() {
            var self = this;
            // busca o primeiro que estiver selecionado
            // porque o botão só estará habilitado se a seleção for valida
            var primeiro = this.$_.find(self.listaMovimentacoesRequisicao, { Selecionado: true });
            var selecao = self.listaMovimentacoesRequisicao.filter(function (x) {
                return !x.Selecionado && x.StatusId < 3;
            });

            var grupos =
                self.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value ? self._grupoSelecao(selecao, self._chaveGrupoRequisicoes) : self._grupoSelecao(selecao, self._chaveGrupoOperacoes);
            // procura o grupo igual ao selecionado
            var faltaSelecionar = self.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value ? grupos[self._chaveGrupoRequisicoes(primeiro)] : grupos[self._chaveGrupoOperacoes(primeiro)];
            if (faltaSelecionar && faltaSelecionar.length > 0) {
                faltaSelecionar.forEach(function (x) {
                    x.Selecionado = true;
                });
            }

            self._atualizaAcoesRequisicao();
        },

        _exibirMensagemValidacaoSelecaoRequisicao() {
            swal.exibirMensagemAlerta(
                "<div class='text-left'>" +
                    '<p>A seleção não é válida para a ação. ' +
                    'Para que a ação possa ser feita, verifique as seguintes condições ' +
                    'e refaça a seleção das requisições:' +
                    '</p>' +
                    '<ul>' +
                    '<li>As requisições precisam estar na mesma etapa</li>' +
                    '<li>As requisições precisam ser todas do mesmo tipo de requisição</li>' +
                    '<li>No caso de venda ambulante, as requisições precisam ser do mesmo vendedor</li>' +
                    '</ul>' +
                    '</div>'
            );
        },
        _exibirMensagemValidacaoSelecaoOperacao() {
            swal.exibirMensagemAlerta(
                "<div class='text-left'>" +
                    '<p>A seleção não é válida para a ação. ' +
                    'Para que a ação possa ser feita, verifique as seguintes condições ' +
                    'e refaça a seleção das operações:' +
                    '</p>' +
                    '<ul>' +
                    '<li>As operações precisam estar na mesma etapa</li>' +
                    '<li>As operações precisam ser todas do mesmo tipo de operação</li>' +
                    '<li>As operações precisam ser do mesmo cliente/fornecedor</li>' +
                    '<li>As operações precisam ser da mesma natureza de operação</li>' +
                    '</ul>' +
                    '</div>'
            );
        }
    }
};
</script>

<style scoped>
.btn-block-mw {
    max-width: 120px;
}
.card-data {
    margin-top: 0px;
    margin-bottom: 5.525rem;
}
.action-buttons {
    max-height: 5.625rem;
}
.card-header-filter {
    /* margin-top: 58px; */
    margin-top: 0px;
    background-color: #f9f9f9;
}
.border-secondary {
    border-color: #ff9200 !important;
    border-width: 3px !important;
}
.border-primary {
    border-color: #5b2e90 !important;
    border-width: 2px !important;
}

.tabela-itens {
    height: calc(100vh - 330px);
}

.cw-1 {
  width: 8%;
}

.cw-2 {
  width: 16%;
}

.cw-3 {
  width: 25%;
}

.cw-4 {
  width: 33%;
}

.cw-5 {
  width: 42%;
}

.cw-6 {
  width: 50%;
}

.cw-7 {
  width: 58%;
}

.cw-8 {
  width: 67%;
}

.cw-9 {
  width: 75%;
}

</style>
